<template>
  <div class="page">
    <SimpleHeader />

    <form class="form-recover-pwd text-center">
      <div class="card">
        <div class="card-body">
          <h1 class="h3 font-weight-normal">Esqueceu sua senha?</h1>
          <p>
            Informe seu e-mail de cadastro para receber as instruções de
            recuperação de senha.
          </p>

          <label for="inputEmail" class="sr-only">E-mail</label>
          <input
            type="email"
            id="inputEmail"
            class="form-control"
            placeholder="E-mail"
            v-model="email"
            autofocus
            maxlength="100"
          />

          <button
            @click.prevent="onClickEnviar"
            :disabled="loading"
            class="btn btn-success btn-block mt-2"
          >
            <span v-if="!loading">Enviar</span>
            <span v-if="loading">
              <small class="text-purple">Enviando...</small>
            </span>
          </button>
        </div>
      </div>
      <!-- <router-link to="/" class="mt-5 d-block"
        ></router-link
      > -->

      <a href="javascript:void(0);" @click="$router.go(-1)" class="mt-5 d-block"><i class="fas fa-chevron-left"></i> Voltar para a tela
        inicial</a>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

import captureError from "../helpers/captureError";
import config from "../config";

import SimpleHeader from "../components/SimpleHeader";

export default {
  components: {
    SimpleHeader,
  },
  data() {
    return {
      loading: false,
      email: "",
    };
  },
  methods: {
    async onClickEnviar() {
      if (!this.email) {
        return;
      }

      this.loading = true;

      try {
        await axios.post(
          `${config.API_URLV2}/auth/esqueciSenha?email=${this.email}`,
          {}
        );

        await Swal.fire({
          icon: "success",
          title: "E-mail de instruções enviado com sucesso.",
        });

        this.$router.go(-1);
      } catch (e) {
        this.loading = false;

        if (e.response && e.response.data && e.response.data.invalido) {
          Swal.fire({
            icon: "warning",
            title: "Atenção",
            text:
              "O e-mail informado não foi encontrado em nossos cadastros. Verifique os dados e tente novamente.",
          });
          return;
        }

        captureError(e, this.email);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível enviar o e-mail. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>

<style scoped>
.page {
  background: #f2f2f2;
  min-height: 100vh;
}
.form-recover-pwd {
  width: 100%;
  max-width: 460px;
  padding: 16px;
  margin: 0 auto;
  margin-top: 120px;
}
</style>
